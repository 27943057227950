var cntrlIsPressed = false;
jQuery(document).keydown(function (event) {
    if (event.which == "17") {
        cntrlIsPressed = true;
    }
});
jQuery(document).keyup(function () {
    cntrlIsPressed = false;
});
function hideshow(value) {
    if (value != '') {
        jQuery('#proof_view').removeClass('hide');
    } else {
        jQuery('#proof_view').addClass('hide');
    }
}

function openmodal(value) {
    if (value != '') {
        jQuery('#modal_theme_primary').modal({
            show: true
        });
    }
}
//for empty otp textbox
jQuery(document).on("click", ".resend_value", function () {
    jQuery('#otp_value').val('');
});
jQuery(document).ready(function () {
    jQuery('[data-toggle="tooltip"]').tooltip();
});

jQuery("#name").on('keyup', function () {
    var Text = jQuery(this).val();
    Text = Text.toLowerCase();
    Text = Text.replace(/[^a-zA-Z0-9]+/g, '-');
    jQuery("#slug").val(Text);
});

jQuery('.single-select').select2();
/* Delete Time Model Open Code */
(function () {
    var laravel = {
        initialize: function () {
            this.methodLinks = $('body');
            this.registerEvents();
        },
        registerEvents: function () {
            //this.methodLinks.on('click', this.handleMethod);
            this.methodLinks.on('click', 'a[data-method]', this.handleMethod);
        },
        handleMethod: function (e) {
            e.preventDefault();
            var link = $(this);
            var csrf_token = jQuery('meta[name="csrf-token"]').attr('content');
            var httpMethod = link.data('method').toUpperCase();
            var allowedMethods = ['PUT', 'DELETE', 'GET'];
            var extraMsg = link.data('modal-text');
            var reject = link.data('reject');
            if (reject) {
                var msg = '<i class="fa fa-exclamation-triangle fa-2x" style="vertical-align: middle; color:#f39c12;"></i>' + rejectStatus_msg + extraMsg;
            } else {
                var msg = '<i class="fa fa-exclamation-triangle fa-2x" style="vertical-align: middle; color:#f39c12;"></i>' + delete_msg + extraMsg;
            }

            // If the data-method attribute is not PUT or DELETE,
            // then we don't know what to do. Just ignore.
            if ($.inArray(httpMethod, allowedMethods) === -1) {
                return;
            }
            bootbox.dialog({
                message: msg,
                title: please_confirm,
                buttons: {
                    success: {
                        label: cancel_btn,
                        className: "btn-default",
                        callback: function () {}
                    },
                    danger: {
                        label: ok_btn,
                        className: "btn-success",
                        callback: function () {
                            var form = $('<form>', {
                                'method': 'POST',
                                'action': link.attr('href')
                            });
                            var hiddenInput = $('<input>', {
                                'name': '_method',
                                'type': 'hidden',
                                'value': link.data('method')
                            });
                            var tokenInput = $('<input>', {
                                'name': '_token',
                                'type': 'hidden',
                                'value': csrf_token
                            });
                            form.append(tokenInput);
                            form.append(hiddenInput).appendTo('body').submit();
                        }
                    }
                }
            });
        }
    };
    laravel.initialize();
})();
/* DatePicker Code */
jQuery(".datepicker").datetimepicker({
    format: 'd-m-Y',
    allowBlank: true,
    timepicker: false,
    mask: true,
    validateOnBlur: false,
    showOn: "button",
    scrollInput: false,
    scrollMonth: false,
});
/* DateTimePicker */
jQuery(".datetimepicker").datetimepicker({
    format: 'd-m-Y H:i:s',
    allowBlank: true,
    timepicker: true,
    mask: true,
    validateOnBlur: false,
    showOn: "button"
});
jQuery(".timepicker").datetimepicker({
    datepicker: false,
    timepicker: true,
    format: 'H:i',
    formatTime: 'h:i A',
    allowBlank: true,
});
// for Image change Code
(function ($) {
    $.imageChanger = function (input, site, default_img) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                $("#" + site).css('visibility', 'visible');
                $("#" + site).attr('src', e.target.result);
                if (default_img) {
                    $("#" + default_img).remove();
                }
            };
            reader.readAsDataURL(input.files[0]);
        }
    };
})(jQuery);

/* Prevent Form Submit */
restrictEnterkey();

function restrictEnterkey() {
    jQuery('input[type="text"]:not(.allow-enter), input:checkbox').on('keyup keypress', function (e) {
        var keyCode = e.keyCode || e.which;
        //console.log(keyCode);
        if (keyCode === 13) {
            // if(!$(this).hasClass("allow-enter")){
            e.preventDefault();
            return false;
            // }
        }
    });
    jQuery(document).on("keydown", function (e) {
        // if (e.which === 8 && !jQuery(e.target).is("input, textarea")) {
        //     e.preventDefault();
        // }
    });
}

jQuery(".setButton").click(function(){
    $(".contact-pannel").removeClass('hide');
});

/* Add Active Class */
jQuery(function () {
    var pgurl = window.location.href;
    jQuery(".navigation li a").each(function () {
        if (jQuery(this).attr("href") == pgurl) {
            jQuery(this).parent().addClass('active');
        }
    });
});
/* For Image Lightbox */
jQuery('[data-popup="lightbox"]').fancybox({
    padding: 3,
    type: 'image',
    openEffect: 'none',
    closeEffect: 'none',
    beforeShow: function () {
        this.title = $(this.element).attr('data-title');
    }
});

/* Dynamic Tabindex */
function dynamicTabIndex() {
    var tabvalue = 0;
    jQuery(" select:not(.noCustomTabIndex), .box a:not(.noCustomTabIndex).customTabindex, .box :input:not(.ui-select-search,.noCustomTabIndex)[type=text], .customTabindex , a.customTabindex, textarea.customTabindex, .ui-select-offscreen, button .customTabindex, input[type=checkbox].customTabindex, input[type=radio].customTabindex, input[type=file].customTabindex , .page-header a.customTabindex, input[type=radio].customTabindex,select.select-size,select.select-size-sm,select.customTabindex,span.select2 > span.selection >span.select2-selection").each(function (i) {
        jQuery(this).removeAttr('tabindex');
        tabvalue = tabvalue + 1;
        jQuery(this).attr('tabindex', tabvalue);
        jQuery(this).addClass('data-tabindex');
    });
}
/* Function Used In Set Error in Modal */
function associate_errors(errors, $form) {
    //remove existing error classes and error messages from form groups
    $form.find('.form-group').removeClass('has-error').find('.help-block').text('');
    jQuery("form").find('.help-block').text('');
    jQuery.each(errors, function (index, value) {
        //find each form group, which is given a unique id based on the form field's name
        var $group = $form.find('#' + index).parents('.form-group');
        if ($group.addClass('has-error').find('.help-block').length == 0) {
            $group.find('.form-control').parent('div').append("<span class='help-block'></span>");
        }
        //add the error class and set the error text
        $group.addClass('has-error').find('.help-block').text(value);
    });
}
//Method for Add more validation message while ajax
function associate_errors_multi(errors, $form) {
    //remove existing error classes and error messages from form groups
    jQuery(".dynamic").find('.error').removeClass('errors')
    jQuery(".dynamic").find('.error').text('')
    jQuery.each(errors, function (index, value) {
        if (index.indexOf("ajax_file") >= 0) {
            index = index.replace("ajax_file", "value");
        }
        var group = jQuery("div").find("[data-tdname='" + index + "']");
        if (group.addClass('has-error').find('.help-block').length == 0) {
            group.find('.control').parent('div').append("<span class='help-block'></span>");
        }
        group.addClass('has-error').find('.help-block').text(value['0']);
    });
}

//Code for User view PopUp
(function () {
    var UserInfo = {
        initialize: function () {
            this.methodLinks = jQuery('body');
            this.registerEvents();
        },
        registerEvents: function () {
            this.methodLinks.on('click', 'a[data-user-show]', this.handleMethod);
        },
        handleMethod: function (e) {
            e.preventDefault();
            var user_id = jQuery(this).attr("data-user-show");
            var url = "/users/" + user_id + "?download=yes";
            jQuery.get(url, function (html) {
                jQuery('#show_user_modal .modal-content').html(html);
                jQuery('#show_user_modal').modal('show', {
                    backdrop: 'static'
                });
            });
        }
    };
    UserInfo.initialize();
})();
//Code for User view PopUp
(function () {
    var OrderInfo = {
        initialize: function () {
            this.methodLinks = jQuery('body');
            this.registerEvents();
        },
        registerEvents: function () {
            this.methodLinks.on('click', 'a[data-order-show]', this.handleMethod);
            this.methodLinks.on('click', '#show_user_modal .close', this.closeMethod);
        },
        handleMethod: function (e) {
            e.preventDefault();
            var user_id = jQuery(this).attr("data-order-show");
            var lang = jQuery(this).attr('data-lang');
            if (jQuery(this).attr("data-noActive")) {
                var noActive = jQuery(this).attr("data-order-show");
                var url = lang + "&flag=true";
            } else {
                var url = lang + "&download=yes";
            }
            jQuery.get(url, function (html) {
                jQuery('#show_user_modal .modal-body').html(html);
                jQuery('#show_user_modal').modal('show', {
                    backdrop: 'static'
                });
            });
        },
        closeMethod: function (e) {
            e.preventDefault();
            jQuery('#show_user_modal').modal('hide');
        }
    };
    OrderInfo.initialize();
})();
/* End Code for Order Show */
/* File Upload */
function singleFileUpload() {
    var file = $('.file-upload');
    //console.log(file);
    var form = $('form.form-locker');
    var textbox = file.data('display');
    //console.log(textbox);
    var uploadErrors = [];
    file.fileupload({
        url: '/ajax/uploadfile/',
        dataType: 'json',
        method: 'POST',
        autoUpload: false,
        //acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
        add: function (e, data) {
            $('.' + textbox).find('p').remove();
            $('#' + textbox).val('');
            $('.' + textbox).html('');
            var acceptFileTypes = /^image\/(jpe?g|png)$/i;
            if (!acceptFileTypes.test(data.originalFiles[0]['type'])) {
                uploadErrors.push('The file type allow only jpeg, jpg, png.');
            } else {
                data.submit();
            }
            if (uploadErrors.length > 0) {
                $('<p style="color: red;">Upload file error: ' + uploadErrors + '<i class="elusive-remove" style="padding-left:10px;"/></p>')
                    .appendTo('.' + textbox);
            }
        },
        done: function (e, data) {
            $('#' + textbox).val(data.files[0].name.toLowerCase());
            $('.' + textbox).html(data.files[0].name.toLowerCase());
        },
        progressall: function (e, data) {
            var progress = parseInt(data.loaded / data.total * 100, 10);
            $('#' + textbox).val(progress + '%');
            $('.' + textbox).html(progress + '%');
        },
        fail: function (e, data) {
            $.each(data.messages, function (index, error) {
                $('<p style="color: red;">Upload file error: ' + error + '<i class="elusive-remove" style="padding-left:10px;"/></p>')
                    .appendTo('#' + textbox);
            });
            $('#' + textbox).val(data.files[0].name.toLowerCase())
            $('.' + textbox).html(data.files[0].name.toLowerCase());
        }
    });
}
jQuery('body').on("focus", ".modal-dialog", function (e) {
    singleFileUpload();
});
// function to create slug from name
// @param nameFieldId -- id of the name field
// @param slugFieldId -- id of the slug field
function createSlug(nameFieldId, slugFieldId) {
    var Text = jQuery("#" + nameFieldId).val();
    Text = Text.toLowerCase();
    Text = Text.replace(/[^a-zA-Z0-9]+/g, '-');
    // alert(Text);
    jQuery("#" + slugFieldId).val(Text);
}

getUserNotification();
var notifyExist = [];
/**
 *  For Regenerate notification
 **/
jQuery(document).on('click', '.notify-set-button', function (event) {
    var parant = jQuery(this).parent().parent().parent();
    if ((parant.find('.notifyTimeTextbox').val() !== '' && typeof parant.find('.notifyTimeTextbox').val() !== 'undefined') ||
        (parant.find('.notifyCommentBox').val() !== '' && typeof parant.find('.notifyCommentBox').val() !== 'undefined')) {
        var notification_id = parant.parent().find('._notify_notification_id').val();
        var notify_id = parant.parent().find('._notify_id').val();
        var time = parant.parent().find('.notifyTimeTextbox').val();
        var comment = parant.parent().find('.notifyCommentBox').val();
        setNotificationAgain(notification_id, notify_id, time, comment, parant);
    }
});

/**
 *  when click on reminder button to open text about timepicker
 **/
jQuery(document).on('click', '.toggleNotifyDatetime', function (event) {
    jQuery(this).parent().parent().find('.notifyDatetimeSection').fadeToggle().removeClass('hide');
});

/**
 *  when click on reminder button to open comment text box
 **/
jQuery(document).on('click', '.toggleNotifyComment', function (event) {
    jQuery(this).parent().parent().find('.notifyCommentSection').fadeToggle().removeClass('hide');
});

/**
 *  when click on close button update notification status from 'unread' to 'read'
 **/
jQuery(document).on("click", ".ui-pnotify-closer", function (e) {
    var notify_id = jQuery(this).parent().parent().find('._notify_id').val();
    setNotificationRead(notify_id);
});

/**
 *  When user click on close button this method is fier ajax in backend to update status
 *  call from ("click", ".ui-pnotify-closer")
 **/
/*
function sendOtp(mobile_no,resend) {

       if(mobile_no !== "" && mobile_no !== null){
           jQuery.ajax({
               type:"POST",
               url: "<?php echo route('ajax.sendOtpByMobile'); ?>",

               success:function(result){
                   console.log(result);
                   var notification;
                   if(result.fail){
                       jQuery('.notified').removeClass('hide');
                       notification = '<br><div class="row">';
                       notification += '<div class="col-sm-5">';
                       notification += '<div class="alert alert-danger alert-dismissable">';
                       notification += '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>';
                       notification += '<strong>Error:</strong> '+result.errors;
                       notification += '</div>';
                       notification += '</div>';
                       jQuery('.notified').html(notification);
                   }else{
                       jQuery('.notified').removeClass('hide');
                       jQuery('.modal-footer').removeClass('hide');
                       notification = '<br><div class="row">';
                       notification += '<div class="col-sm-5">';
                       notification += '<div class="alert alert-success alert-dismissable">';
                       notification += '<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>';
                       notification += '<strong>Success:</strong> '+result.result;
                       notification += '</div>';
                       notification += '</div>';
                       jQuery('.notified').html(notification);
                       jQuery('#otp_id').removeClass('hide');
                   }
               }
           });
       }
   }
*/
function setNotificationRead(notify_id) {

    if (typeof notify_id !== "undefined" && notify_id > 0) {
        var csrf_token = jQuery('meta[name="csrf-token"]').attr('content');
        jQuery.ajax({
            type: "POST",
            url: "/notifyajax/notification-read",
            data: {
                _token: csrf_token,
                notify_id: notify_id
            }
        }).success(function (result) {
            return 1;
        }).error(function () {
            return 0;
        });
    }
}

/**
 *  Every 5 minutes this method will fier ajax in backed
 *  1st time call when page is load
 *  call from getUserNotification()
 **/
function getUserNotification() {
    jQuery.ajax({
        type: "GET",
        url: "/notifyajax/user-notification"
    }).success(function (result) {
        showNotification(result);
    });
}

/**
 * Method used to create pnotify object
 **/
function createNotification(title, text, type, notification_id, notify_id, url, creater, comment, created_at = null) {
    var content = '<div class="pnotify-content">' + text + '<input type="hidden" value="' + notification_id + '" class="_notify_notification_id" />';
    content += '<input type="hidden" value="' + notify_id + '" class="_notify_id" /></div>';
    content += '<div class="pnotify-footer text-right">';
    content += '<div class="clearfix">';
    content += '<span class="col-xs-10 notifyDatetimeSection hide pull-left notify-padding-remove">';
    content += '<input type="text" placeholder="want to say something ?" class="commentTextBox notifyCommentBox notifyTextbox notify-min-height" />';
    content += '<input type="text" class="timepicker notifyTimeTextbox notifyTextbox notify-min-height" />';
    content += '<button class="notify-set-button">Set</button>';
    content += '</span>';
    content += '<span class="col-xs-1 notify-padding-remove pull-right notify-min-height">';
    content += '<span class="toggleNotifyDatetime"><i class="fa fa-bell" aria-hidden="true" title="reminder"></i></span>';
    content += '</span>';
    content += '</div>';
    content += '<div class="text-left notify-comment">';
    content += '</div>';
    content += '<div>';
    content += '<div class="pull-left get-link"></div>';
    content += '<div class="text-right pull-right after-me"><small class="notify-creater text-uppercase"></small></div>';
    content += '<div class="clearfix"></div>';
    content += '<div class="text-right pull-right created-at"><small class="notify-created-at text-uppercase"></small></div>';
    content += '<div class="clearfix"></div>';
    content += '</div>';
    content += '</div>';
    PNotify.prototype.options.styling = "bootstrap3";
    new PNotify({
        title: title,
        text: content,
        type: type,
        hide: false,
        opacity: .8,
        buttons: {
            sticker: false,
            closer_hover: false
        },
        before_open: function (hdom) {
            hdom.container.find('.notify-creater').text("Created By :" + creater);
            hdom.container.find('.notify-created-at').text("Created At :" + created_at);
            hdom.container.find('.ui-pnotify-closer').append("&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><button id='closeAllNotifications' style='background-color: white;background-repeat:no-repeat;cursor:pointer;'><b><font style='color:red;'>Close All</font></b></button></span>");
        },
        after_open: function (dom) {
            var link = dom.container.find('.link_message').addClass('pull-left').text();
            dom.container.find('.get-link').html('<a href=' + url + ' target="_blank">' + link + '</a>');
            dom.container.find('.link_message').remove();
            dom.container.find('.notify-comment').text(comment);
            jQuery(".timepicker").datetimepicker({
                datepicker: false,
                timepicker: true,
                format: 'H:i',
                formatTime: 'h:i A',
                step: 5,
                allowBlank: true,
            });
        },
        desktop: {
            desktop: false,
            fallback: true
        }
    });
}

/**
 * When ajax is coming from backend and has data then this method will call
 * call from getUserNotification()
 **/
function showNotification(result) {
    jQuery.each(result, function (index, value) {
        if ('title' in value && 'message' in value && 'status' in value) {
            if (typeof notifyExist[value.id] === 'undefined') {
                createNotification(value.title, value.message, value.status, value.notification_id, value.id, value.url, value.creater, value.comment, value.createdAt);
                notifyExist[value.id] = 1;
            }
        }
    });
}

/**
 * used to create notification again after set time
 * call from ('click','.notify-set-button')
 **/
function setNotificationAgain(notification_id, notify_id, time, comment, domHide) {
    if (notification_id > 0 && notify_id > 0 && (time !== '' || comment !== '')) {
        var csrf_token = jQuery('meta[name="csrf-token"]').attr('content');
        jQuery.ajax({
            type: "POST",
            url: "/notifyajax/notification-create",
            data: {
                _token: csrf_token,
                notification_id: notification_id,
                notify_id: notify_id,
                time: time,
                comment: comment
            }
        }).success(function (result) {
            domHide.parent().parent().fadeOut();
        }).error(function () {
            return false;
        });
    }
}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$(".allownumeric").on("keypress blur", function (event) {
    var theEvent = event || window.event;
    var key = theEvent.keyCode || theEvent.which;
    if (key == 37 || key == 38 || key == 39 || key == 9 || key == 40 || key == 8 || key == 123 || key == 16 || key == 46) {
        return;
    } else {
        key = String.fromCharCode(key);
        var regex = /[0-9]/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }
});
jQuery(document).on("click", "#closeAllNotifications", function (e) {
    jQuery.ajax({
        type: "POST",
        url: "/notifyajax/close-all-notification"
    }).success(function (result) {
        PNotify.removeAll();
    });
});
jQuery(document).ready(function () {
    jQuery(".btn-primary").removeAttr("disabled");
    jQuery(".btn-warning").removeAttr("disabled");
});

jQuery('body').on('dragstart', 'a[data-popup="lightbox"]', function (event) { event.preventDefault(); });
jQuery('body').on('dragstart', '.fancybox-image', function (event) { event.preventDefault(); });
jQuery('a[data-popup="lightbox"]').on('dragstart', function (event) { event.preventDefault(); });
jQuery('a[data-popup="lightbox"]').on('click', function (e) {
    if (cntrlIsPressed)
        e.preventDefault();
});
jQuery('body').on('click', 'a[data-popup="lightbox"]', function (e) {
    if (cntrlIsPressed)
        e.preventDefault();
});